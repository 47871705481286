import Link from 'next/link';
import React from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineTwitter,
} from 'react-icons/ai';

const Footer: React.FC = () => (
  <footer>
    <div className="py-8 bg-white">
      <div className="w-full text-center space-y-2">
        <div className="text-xl font-bold">
          Say 👋{' '}
          <a
            className="text-indigo-700 hover:text-indigo-500"
            href="mailto:hello@lampant.com"
          >
            hello@lampant.com
          </a>
        </div>
        <div className="space-x-4">
          <Link href="/terms">
            <a className="text-xs text-indigo-400 hover:text-indigo-500">
              Terms
            </a>
          </Link>
          <Link href="/privacy">
            <a className="text-xs text-indigo-400 hover:text-indigo-500">
              Privacy
            </a>
          </Link>
          <Link href="/pricing">
            <a className="text-xs text-indigo-400 hover:text-indigo-500">
              Pricing
            </a>
          </Link>
          <Link href="/changelog">
            <a className="text-xs text-indigo-400 hover:text-indigo-500">
              What's new
            </a>
          </Link>
          <a
            href="https://www.saashub.com/lampant-status"
            className="text-xs text-indigo-400 hover:text-indigo-500"
            target="_blank"
            rel="noreferrer"
          >
            Status Page
          </a>
        </div>
        <div className="flex items-center justify-center text-xl space-x-2 text-indigo-800">
          <a
            href="https://twitter.com/getlampant"
            target="_blank"
            rel="noreferrer"
            className="hover:text-indigo-500"
          >
            <AiOutlineTwitter />
          </a>

          <a
            href="https://linkedin.com/company/lampant"
            target="_blank"
            rel="noreferrer"
            className="hover:text-indigo-500"
          >
            <AiFillLinkedin />
          </a>

          <a
            href="https://facebook.com/getlampant"
            target="_blank"
            rel="noreferrer"
            className="hover:text-indigo-500"
          >
            <AiFillFacebook />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
