import React from 'react';
import Head from 'next/head';
import Nav from './Nav';
import Footer from './Footer';

const StaticLayout: React.FC<any> = ({ children, title = 'Lampant' }) => (
  <div>
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <Nav />
    <div className="content">{children}</div>
    <Footer />
  </div>
);

export default StaticLayout;
