import React from 'react';
import Link from 'next/link';
import NavLink from './NavLink';

const Nav: React.FC = () => (
  <>
    <nav className="bg-indigo-400">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link href="/">
                <a>
                  <img
                    className="lg:block h-8 w-auto"
                    src="/images/logo/lampant.png"
                    alt="Lampant"
                  />
                </a>
              </Link>
            </div>
            <div className="ml-8 flex-1 md:flex items-center hidden">
              {/* <NavLink text="Pricing" targetUrl="/pricing" /> */}
            </div>
          </div>
          <div className="hidden md:block order-3 mx-2 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <Link href="/register">
              <a className="flex items-center justify-center px-4 py-2 border border-indigo-100 rounded-md shadow-sm text-sm font-medium bg-indigo-700 text-white hover:bg-indigo-600">
                Register
              </a>
            </Link>
          </div>
          <div className="hidden md:block order-3 mx-2 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="/login"
              className="flex items-center justify-center px-4 py-2 border border-white rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-300"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </nav>
  </>
);

export default Nav;
